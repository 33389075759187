import React from 'react';

import { HeroBanner, CaseHeader, Testimonial, TextContent, Gallery, GalleryLayout, Team } from 'modules/references';

import socialPreview from '../../../static/social-previews/glitter.png';
import Seo from '../../components/SEO';

import { ReferenceCategory } from '../../constants';

const CASE_ID = 'glitter';
const MODULE_NAME = 'glitter';
const IMAGE_PATH = `references/case-studies/${MODULE_NAME}`;

const colors = {
    bg1: '#AB7BE7',
    bg2: '#EA74C2',
    bg3: '#8F4FE0',
    text: '#000',
    violetText: '#7251D1',
    white: '#fff',
};

const team = [
    { department: 'references.case.team.management', names: ['Dominik Veselý'] },
    {
        department: 'references.case.team.backend',
        names: ['Jaroslav Šmolík', 'Pavel Švagr', 'Tomáš Buňata'],
    },
    {
        department: 'references.case.team.devops',
        names: ['Tomáš Hejátko'],
    },
];

const Glitter = () => (
    <>
        <Seo image={socialPreview} pageId={CASE_ID} />
        <CaseHeader />
        <HeroBanner
            content={{
                projectName: `case.${CASE_ID}.hero.project`,
                projectTitle: `case.${CASE_ID}.hero.title`,
                projectYear: `case.${CASE_ID}.hero.year`,
                client: `case.${CASE_ID}.hero.client`,
                image: {
                    src: `${IMAGE_PATH}/glitter_hero.png`,
                    style: { height: '100%', marginTop: '1.5rem' },
                    imgStyle: { objectFit: 'contain', objectPosition: 'bottom' },
                },
            }}
            backgroundImage={{
                src: `${IMAGE_PATH}/glitter_hero_bg.png`,
                imgStyle: { objectFit: 'cover', objectPosition: 'top' },
            }}
            headerTheme="light"
            textColor={colors.white}
        />
        <TextContent
            headerTheme="dark"
            textColor={colors.violetText}
            content={{
                section: `case.${CASE_ID}.section1.name`,
                title: `case.${CASE_ID}.section1.title`,
                text: `case.${CASE_ID}.section1.text`,
                category: ReferenceCategory.FINANCE,
                outputs: ['references.case.output.backend'],
            }}
        />
        <Gallery
            headerTheme="dark"
            fullHeight={false}
            layout={GalleryLayout.EQUAL}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/glitter_gallery_1.png`,
                        imgStyle: {
                            objectFit: 'contain',
                        },
                        style: {
                            width: '80%',
                            height: 'unset',
                            minHeight: 'unset',
                            marginBottom: '3rem',
                        },
                    },
                },
            ]}
            backgroundImage={{
                src: `${IMAGE_PATH}/glitter_gallery_1_bg.png`,
                imgStyle: {
                    objectFit: 'contain',
                    objectPosition: 'bottom',
                },
                style: {},
            }}
        />
        <TextContent
            headerTheme="light"
            content={{
                section: `case.${CASE_ID}.section3.name`,
                title: `case.${CASE_ID}.section3.title`,
                text: `case.${CASE_ID}.section3.text`,
            }}
            background={colors.bg1}
            textColor={colors.white}
        />
        <Gallery
            headerTheme="light"
            layout={GalleryLayout.EQUAL}
            fullHeight={false}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/glitter_gallery_2.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center center',
                        },
                        style: {
                            width: '80%',
                            maxWidth: '100rem',
                        },
                    },
                },
            ]}
            background={`linear-gradient(180deg, ${colors.bg1} 0%, ${colors.bg2} 100%)`}
        />
        <TextContent
            headerTheme="light"
            background={colors.bg2}
            textColor={colors.white}
            content={{
                section: `case.${CASE_ID}.section5.name`,
                title: `case.${CASE_ID}.section5.title`,
                text: `case.${CASE_ID}.section5.text`,
            }}
        />
        <Gallery
            headerTheme="dark"
            fullHeight={false}
            layout={GalleryLayout.EQUAL}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/glitter_gallery_3_bg.png`,
                        imgStyle: {
                            objectFit: 'cover',
                        },
                        style: {
                            width: '100%',
                            height: 'unset',
                            minHeight: 'unset',
                        },
                    },
                },
            ]}
        />
        <TextContent
            headerTheme="dark"
            background={colors.white}
            textColor={colors.violetText}
            content={{
                section: `case.${CASE_ID}.section6.name`,
                title: `case.${CASE_ID}.section6.title`,
                text: `case.${CASE_ID}.section6.text`,
            }}
        />
        <Gallery
            headerTheme="light"
            fullHeight={false}
            layout={GalleryLayout.EQUAL}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/glitter_gallery_4_bg.png`,
                        imgStyle: {
                            objectFit: 'cover',
                        },
                        style: {
                            width: '100%',
                            height: 'unset',
                            minHeight: 'unset',
                        },
                    },
                },
            ]}
        />
        <Testimonial
            headerTheme="light"
            background={`linear-gradient(180deg, ${colors.bg2} 0%, ${colors.bg3} 100%)`}
            content={{
                quote: `case.${CASE_ID}.testimonial.text`,
                person: {
                    name: `case.${CASE_ID}.testimonial.name`,
                    role: `case.${CASE_ID}.testimonial.position`,
                    image: {
                        src: `${IMAGE_PATH}/glitter_testimonial.png`,
                    },
                },
            }}
            type="PROFILE_RIGHT"
            textColor={colors.white}
        />

        <Team headerTheme="light" content={team} background={colors.bg3} textColor={colors.white} />
    </>
);

export default Glitter;
